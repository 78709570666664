import React from 'react'

import { media, rem } from '../styles/tools'

import Container from './Container'
import Link from './Link'
import Space from './Space'
import {
  WideText,
  BoldTextMedium,
  Underline,
  SocialLink,
} from '../styles/typography'
import ScrollToLink from './ScrollToLink'

const Footer = props => (
  <Container as="footer" {...props}>
    <Space y={{ mobile: 100, tabletLg: 200 }} />
    <div css={{ display: 'flex', flexWrap: 'wrap' }}>
      <div css={{ flex: 1 }}>
        <WideText>GET IN TOUCH</WideText>
        <Space y={20} />
        <ul
          css={{
            display: 'inline-grid',
            gridAutoFlow: 'column',
            gridGap: rem(20),
            [media.mobileLg]: { gridGap: rem(10) },
          }}
        >
          <li>
            <SocialLink
              as={Link}
              out
              href="https://twitter.com/creativeanthony"
              style={{ '--color': '#05B2FF' }}
            >
              TW
            </SocialLink>
          </li>
          <li>
            <SocialLink
              as={Link}
              out
              href="https://www.instagram.com/anthonydesigner/"
              style={{ '--color': '#E112FC' }}
            >
              IG
            </SocialLink>
          </li>
          <li>
            <SocialLink
              as={Link}
              out
              href="https://www.linkedin.com/in/anthonywiktor/"
              style={{ '--color': '#094290' }}
            >
              LN
            </SocialLink>
          </li>
          <li>
            <SocialLink
              as={Link}
              out
              href="https://www.behance.net/ad_victorem"
              style={{ '--color': '#1F86FF' }}
            >
              BE
            </SocialLink>
          </li>

          <li>
            <SocialLink
              as={Link}
              out
              href="https://medium.com/advictorem"
              style={{ '--color': '#00A257' }}
            >
              M
            </SocialLink>
          </li>
        </ul>
        <Space y={{ mobile: 50, tabletLg: 100 }} />
        <BoldTextMedium
          as="p"
          css={{
            maxWidth: '16em',
            [media.tabletLg]: { maxWidth: '21em' },
          }}
        >
          Want to move forward?
          <br />
          <Underline as="a" href="mailto:hello@anthonydesigner.com">
            Drop a line.
          </Underline>
        </BoldTextMedium>
      </div>
      <div
        css={{
          flex: '100%',
          alignSelf: 'flex-end',
          [media.tabletLg]: { flex: 'initial' },
        }}
      >
        <Space y={{ mobile: 50, tabletLg: 100 }} />
        <WideText
          as={ScrollToLink}
          href="#"
          css={{
            margin: `0 ${rem(-40)} ${rem(-40 / 2)}`,
            padding: rem(40),
            color: '#777',
            cursor: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.57' height='42.65' viewBox='0 0 18.57 42.65'%3E%3Cpath fill='%23fff' d='M.87 10.15l7.8-7.8v40.3H9.9V2.35l7.8 7.8.87-.87L10.15.87 9.28 0l-.87.87L0 9.28z'/%3E%3C/svg%3E"), auto`,
            transition: 'opacity .3s',
            ':hover': { opacity: 0.5 },
          }}
        >
          Back to top
        </WideText>
      </div>
    </div>
    <Space y={{ mobile: 100, tabletLg: 200 }} />
  </Container>
)

export default Footer
