import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import { Location } from '@reach/router'

const SEO = ({
  lang,
  title,
  description,
  keywords,
  meta,
  facebookImage,
  twitterImage,
}) => {
  const data = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          lang
          title
          description
          keywords
          siteUrl
          siteName
          twitterCreator
        }
      }
      facebookImage: file(relativePath: { eq: "share-image.jpg" }) {
        ...facebookShareImage
      }
      twitterImage: file(relativePath: { eq: "share-image.jpg" }) {
        ...twitterShareImage
      }
    }
  `)

  const metaTitle = title
    ? `${title} • ${data.site.siteMetadata.title}`
    : data.site.siteMetadata.title
  const metaDescription = description || data.site.siteMetadata.description
  const metaKeywords = keywords || data.site.siteMetadata.keywords

  const metaFacebookImage =
    data.site.siteMetadata.siteUrl +
    (facebookImage || data.facebookImage.childImageSharp.fixed.src)
  const metaTwitterImage =
    data.site.siteMetadata.siteUrl +
    (twitterImage || data.twitterImage.childImageSharp.fixed.src)

  return (
    <Location>
      {({ location: { pathname } }) => (
        <Helmet
          htmlAttributes={{ lang }}
          title={metaTitle}
          meta={[
            {
              name: 'description',
              content: metaDescription,
            },
            metaKeywords.length > 0
              ? {
                  name: 'keywords',
                  content: metaKeywords.join(', '),
                }
              : {},

            // Used by Facebook and Twitter
            {
              property: 'og:title',
              content: metaTitle,
            },
            {
              property: 'og:description',
              content: metaDescription,
            },
            {
              property: 'og:url',
              content: data.site.siteMetadata.siteUrl + pathname,
            },

            // Facebook specific
            {
              property: 'og:site_name',
              content: data.site.siteMetadata.siteName,
            },
            {
              property: 'og:locale',
              content: lang,
            },
            {
              property: 'og:image',
              content: metaFacebookImage,
            },
            {
              property: 'og:type',
              content: 'website',
            },

            // Twitter specific
            {
              name: 'twitter:creator',
              content: data.site.siteMetadata.twitterCreator,
            },
            {
              name: 'twitter:image',
              content: metaTwitterImage,
            },
            {
              name: 'twitter:card',
              content: 'summary_large_image',
            },

            ...meta,
          ]}
        />
      )}
    </Location>
  )
}

SEO.defaultProps = {
  lang: 'en',
  keywords: [],
  meta: [],
}

SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
