import React, { Component } from 'react'
import { TweenMax, Power4 } from 'gsap'

class ScrollToLink extends Component {
  static defaultProps = {
    duration: 1,
    offset: 0,
  }

  scrollToTween = null

  handleClick = e => {
    this.href = e.currentTarget.getAttribute('href')

    if (this.href[0] !== '#') return
    e.preventDefault()

    if (this.href === '#') this.scrollTo(0)
    else this.scrollTo(this.href)
  }

  scrollTo = selectorOrNumber => {
    let scrollTop = 0

    if (typeof selectorOrNumber === 'number') {
      scrollTop = selectorOrNumber
    } else {
      const element = document.querySelector(selectorOrNumber)
      scrollTop = window.pageYOffset + element.getBoundingClientRect().top
    }

    scrollTop =
      scrollTop - this.props.offset >= this.props.offset
        ? scrollTop - this.props.offset
        : 0

    this.scrollToTween = TweenMax.to(
      [document.body, document.documentElement],
      this.props.duration,
      {
        scrollTop,
        ease: Power4.easeInOut,
        onComplete: () => {
          window.history.pushState(null, null, this.href)
          window.removeEventListener('wheel', this.cancelScrollToTween)
          window.removeEventListener('touchstart', this.cancelScrollToTween)
        },
      }
    )
    window.addEventListener('wheel', this.cancelScrollToTween)
    window.addEventListener('touchstart', this.cancelScrollToTween)
  }

  cancelScrollToTween = () => {
    this.scrollToTween.pause()
    window.removeEventListener('wheel', this.cancelScrollToTween)
    window.removeEventListener('touchstart', this.cancelScrollToTween)
  }

  render() {
    const { offset, ...rest } = this.props

    // eslint-disable-next-line
    return <a onClick={this.handleClick} {...rest} />
  }
}

export default ScrollToLink
