import React from 'react'
import { keyframes } from '@emotion/core'

import useScrolledBelow from '../hooks/useScrolledBelow'

import ScrollToLink from './ScrollToLink'

const pulse = keyframes({
  '0%': {
    transformOrigin: '0% 0%',
    transform: 'scale(1, 0)',
  },
  '50%': {
    transformOrigin: '0% 0%',
    transform: 'scale(1, 1)',
  },
  '50.001%': {
    transformOrigin: '0% 100%',
    transform: 'scale(1, 1)',
  },
  '100%': {
    transformOrigin: '0% 100%',
    transform: 'scale(1, 0)',
  },
})

const Arrow = props => (
  <div css={{ padding: '20px 100px', transition: 'opacity 1s' }} {...props}>
    <div
      css={{
        width: 1,
        height: 35,
        background: 'currentColor',
        animation: `${pulse} 2s cubic-bezier(.8, 0, .2, 1) infinite`,
      }}
    />
  </div>
)

const ScrollArrow = props => {
  const isScrolledDown = useScrolledBelow(100)

  return (
    <ScrollToLink {...props}>
      <Arrow style={{ opacity: isScrolledDown ? 0 : 1 }} />
    </ScrollToLink>
  )
}

export default ScrollArrow
